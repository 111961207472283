// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';

// const Fouter = () => {
//   return (
//     <div id="fouter">
//     <footer className="bg-dark text-white text-center " >
//       <Container>
//         <Row className="mb-3">
//           <Col>
//             <div className="socialMed">
//               <a href="https://www.facebook.com/" className="text-white mx-2">
//                 <i className="fab fa-facebook fa-2x"></i>
//               </a>
//               <a href="https://twitter.com/?lang=en" className="text-white mx-2">
//                 <i className="fab fa-twitter fa-2x"></i>
//               </a>
//               <a href="https://www.instagram.com/" className="text-white mx-2">
//                 <i className="fab fa-instagram fa-2x"></i>
//               </a>
//               <a href="https://www.linkedin.com" className="text-white mx-2">
//                 <i className="fab fa-linkedin fa-2x"></i>
//               </a>
//             </div>
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <p>&copy; {new Date().getFullYear()} E-commerce. Tous droits réservés.</p>
//           </Col>
//         </Row>
//       </Container>
//     </footer>
//       </div>);
// }

// export default Fouter;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaWhatsapp } from 'react-icons/fa';
import './Footer.css';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="footer-bottom">
                    <Col md={12}>
                       
                        <img src="../images/logobb.png" alt="" data-aos='zoom-in' style={{width:"180px" , height:"45px"}}/>
                          
                      
                        <p>&copy; {new Date().getFullYear()} E-commerce. Tous droits réservés.</p>
                    </Col>
                    <Col>
                        <a href="https://wa.me/" className="whatsapp-icon">
                            <FaWhatsapp size={50} />
                        </a>
                    </Col>
               
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;


// <Row>
// {/* <Col md={4} className="footer-about">
//     <h5>POLYSOFT&CO</h5>
//     <p>Une Start Up Tunisienne basée à Sfax créée en Février 2019 spécialisée dans le développement de solutions informatiques.</p>
// </Col> */}
// <Col md={4} className="footer-links">
//     <h5>Liens utiles</h5>
//     <ul>
//         <li><a href="/">Accueil</a></li>
//         <li><a href="/a-propos">A propos</a></li>
//         {/* <li><a href="/services">Services</a></li> */}
//         {/* <li><a href="/nos-packs">Nos Packs</a></li> */}
//         <li>
//         <Link to="/contact" className="contact-button">
//             Contact
//            </Link>
//         </li>
//     </ul>
// </Col>
// <Col md={4} className="footer-contact">
//     <h5>Contactez-nous</h5>
//     <ul>
//         <li><FaPhone /> Téléphone : +216 74 433 020</li>
//         <li><FaEnvelope /> Email : <a href="mailto:gm@polysoftco.tn">gm@polysoftco.tn</a></li>
//         <li><FaMapMarkerAlt /> Adresse : <a href="https://www.google.com/maps/place/POLYSOFT/@34.753891,10.758659,19z/data=!4m6!3m5!1s0x1301d3fcbdf5f629:0x2fe90eeb77a94e4b!8m2!3d34.753796!4d10.7584861!16s%2Fg%2F11hnpsh09s?entry=ttu">Rte de Teniour Km 1,5 / Sfax, Tunisia, 3000 </a> </li>
//     </ul>
// </Col>
// </Row>

