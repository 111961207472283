import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getClientProfile, updateClient } from '../redux/action';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import './Profil.css'; // Import the CSS file

const Profile = () => {
  const { client, loading } = useSelector((state) => state.client);
  const dispatch = useDispatch();

  const [codCli, setCodCli] = useState('');
  const [adress, setAdress] = useState('');
  const [telephone, setTelephone] = useState('');

  useEffect(() => {
    dispatch(getClientProfile());
  }, [dispatch]);

  useEffect(() => {
    if (client) {
      setCodCli(client.codcli);
      setAdress(client.adr);
      setTelephone(client.tel1);
    }
  }, [client]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const clientData = {
      adr: adress,
      tel1: telephone,
    };
    dispatch(updateClient(codCli, clientData));
  };

  return (
    <div>
       <div className="cart-header bg-secondary p-4 text-white mb-3" >
        <h1 className="display-6">Profile</h1>
        </div>
    
    <div id="Profile">
         
        
      {loading && <Spinner animation="border" />}
      {client && (
     
        <Form onSubmit={handleSubmit}>
          <h2 style={{color:"black" }}>Mon compte</h2>
          <Row>
            <Form.Group  controlId="formGridEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={client.email}
                readOnly
              />
            </Form.Group>
            </Row>
            <Row>
            <Form.Group  controlId="formGridPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={client.mp}
                readOnly
              />
            </Form.Group>
          </Row>
<Row>
          <Form.Group className="mb-4" controlId="formGridTel1">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Téléphone 1"
              name="tel1"
              value={telephone}
              onChange={e => setTelephone(e.target.value)} 
            />
          </Form.Group>
</Row>
<Row>
          <Form.Group className="mb-" controlId="formGridAdr">
            <Form.Label>Adresse</Form.Label>
            <Form.Control
              type="text"
              placeholder="Adresse"
              name="adr"
              value={adress}
              onChange={e => setAdress(e.target.value)} 
            />
          </Form.Group>
          </Row>
          <br></br>
          <Button variant="warning" type="submit">
            Modifier
          </Button>
        </Form>
      )}
   </div>
    </div>);
};

export default Profile;
