// reducerClient.js
import {
    GET_ALL_CLIENTS,
    GET_CLIENT_PROFILE,
    GET_CLIENT_PROFILE_SUCCESS,
    GET_CLIENT_PROFILE_FAIL,
    ADD_CLIENT,
    ADD_CLIENT_SUCCESS,
    ADD_CLIENT_FAIL,
    DELETE_CLIENT,
    SIGN_UP,
    LOGIN,
    SIGN_UP_SUCCESS,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    SIGN_UP_FAIL,
    LOGOUT,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_CLIENT_FAILURE,
    
} from "./actionTypes";

const initialState = {
    clients: null,
    client: null,
    update:null,
    error: null,
    loading: false,
    token:localStorage.getItem("token"),
    isAuth:false,
};

export const clientReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SIGN_UP:
        case LOGIN:
        case ADD_CLIENT:
        case GET_CLIENT_PROFILE:
            return {
                ...state,
                loading: true,
            };
        case ADD_CLIENT_SUCCESS:
        case SIGN_UP_SUCCESS:
            return {
                ...state,
                loading: false,
                client:payload.client,
                token:payload.token,
                isAuth:true,
                error: null,
            };

            case LOGIN_SUCCESS:
                return {
                ...state,
                loading:false,
                client:payload.client,
                token:payload.token,
                isAuth:true,
                error: null,};

                
        case GET_CLIENT_PROFILE_SUCCESS:
            return {
                ...state,
                client: payload,
                loading: false,
                error: null,
            };

            
        case SIGN_UP_FAIL:
        case LOGIN_FAIL:
        case ADD_CLIENT_FAIL:
        case GET_CLIENT_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case GET_ALL_CLIENTS:
            return {
                ...state,
                clients: payload,
                loading: false,
                error: null,
            };
        case DELETE_CLIENT:
            return {
                ...state,
                clients: state.clients.filter(client => client._id !== payload),
            };
        // case UPDATE_CLIENT:
        //     return {
        //         ...state,
        //         clients: state.clients.map(client => client.codcli === payload.codcli ? payload : client),
               
        //     };
        case UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                update: payload,
                error: null,
            };
        case UPDATE_CLIENT_FAILURE:
            return {
                ...state,
                error: payload,
            };
            case LOGOUT:
                return {
                    ...state,
                    loading: false,
                    client: null,
                    token: null,
                    isAuth: false,
                    error: null,
                };
        default:
            return state;
    }
};
