// actionTypes.js
// Types d'actions pour les clients
export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAIL = "SIGN_UP_FAIL";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";
export const BLOCK_CLIENT = "BLOCK_CLIENT";

export const ADD_CLIENT = "ADD_CLIENT";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAIL = "ADD_CLIENT_FAIL";
export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";
export const GET_CLIENT_PROFILE = "GET_CLIENT_PROFILE";
export const GET_CLIENT_PROFILE_SUCCESS = "GET_CLIENT_PROFILE_SUCCESS";
export const GET_CLIENT_PROFILE_FAIL = "GET_CLIENT_PROFILE_FAIL";
export const DELETE_CLIENT = "DELETE_CLIENT";
// export const UPDATE_CLIENT = "UPDATE_CLIENT";

export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';

// Types d'actions pour les articles
export const ADD_ARTICLE = "ADD_ARTICLE";
export const ADD_ARTICLE_SUCCESS = "ADD_ARTICLE_SUCCESS";
export const ADD_ARTICLE_FAIL = "ADD_ARTICLE_FAIL";


export const GET_ARTICLE_DETAIL = "GET_ARTICLE_DETAIL";
export const GET_ARTICLE_DETAIL_SUCCESS = "GET_ARTICLE_DETAIL_SUCCESS";
export const GET_ARTICLE_DETAIL_FAIL = "GET_ARTICLE_DETAIL_FAIL";


export const GET_ARTICLE_DES = "GET_ARTICLE_DES";
export const GET_ARTICLE_DES_SUCCESS = "GET_ARTICLE_DES_SUCCESS";
export const GET_ARTICLE_DES_FAIL = "GET_ARTICLE_DES_FAIL";


export const GET_ACC_PRODUCTS = 'GET_ACC_PRODUCTS';
export const GET_ACC_PRODUCTS_SUCCESS = 'GET_ACC_PRODUCTS_SUCCESS';
export const GET_ACC_PRODUCTS_FAIL = 'GET_ACC_PRODUCTS_FAIL';

export const GET_SF01_PRODUCTS = 'GET_SF01_PRODUCTS';
export const GET_SF01_PRODUCTS_SUCCESS = 'GET_SF01_PRODUCTS_SUCCESS';
export const GET_SF01_PRODUCTS_FAIL = 'GET_SF01_PRODUCTS_FAIL';



export const GET_ALL_ARTICLES = "GET_ALL_ARTICLES";
export const GET_ALL_ARTICLES_SUCCESS = "GET_ALL_ARTICLES_SUCCESS";
export const GET_ALL_ARTICLES_FAIL = "GET_ALL_ARTICLES_FAIL";






export const GET_ALL_FAM_PRODUCTS = 'GET_ALL_FAM_PRODUCTS';
export const GET_ALL_FAM_PRODUCTS_ERROR = 'GET_ALL_FAM_PRODUCTS_ERROR';







export const GET_ARTICLES_BY_FAMILY_REQUEST = 'GET_ARTICLES_BY_FAMILY_REQUEST';
export const GET_ARTICLES_BY_FAMILY_SUCCESS = 'GET_ARTICLES_BY_FAMILY_SUCCESS';
export const GET_ARTICLES_BY_FAMILY_FAILURE = 'GET_ARTICLES_BY_FAMILY_FAILURE';



export const GET_BY_SOUS_FAM_ART_REQUEST = 'GET_BY_SOUS_FAM_ART_REQUEST';
export const GET_BY_SOUS_FAM_ART_SUCCESS = 'GET_BY_SOUS_FAM_ART_SUCCESS';
export const GET_BY_SOUS_FAM_ART_FAILURE = 'GET_BY_SOUS_FAM_ART_FAILURE';


export const GET_ARTICLES_BY_FAMART_AND_SOUSFAM_REQUEST = 'GET_ARTICLES_BY_FAMART_AND_SOUSFAM_REQUEST';
export const GET_ARTICLES_BY_FAMART_AND_SOUSFAM_SUCCESS = 'GET_ARTICLES_BY_FAMART_AND_SOUSFAM_SUCCESS';
export const GET_ARTICLES_BY_FAMART_AND_SOUSFAM_FAILURE = 'GET_ARTICLES_BY_FAMART_AND_SOUSFAM_FAILURE';






export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";






// action types cart 

export const CARD_ADD_ITEM = 'CARD_ADD_ITEM';
export const CARD_ADD_ITEM_SUCCESS = 'CARD_ADD_ITEM_SUCCESS';
export const CARD_ADD_ITEM_FAIL = 'CARD_ADD_ITEM_FAIL';


export const CART_GET_ITEMS_REQUEST = 'CART_GET_ITEMS_REQUEST';
export const CART_GET_ITEMS_SUCCESS = 'CART_GET_ITEMS_SUCCESS';
export const CART_GET_ITEMS_FAIL = 'CART_GET_ITEMS_FAIL';

export const CART_UPDATE_ITEM = 'CART_UPDATE_ITEM';
export const CART_UPDATE_ITEM_SUCCESS = 'CART_UPDATE_ITEM_SUCCESS';
export const CART_UPDATE_ITEM_FAIL = 'CART_UPDATE_ITEM_FAIL';


export const CART_GET_ALL_ITEMS_REQUEST = 'CART_GET_ALL_ITEMS_REQUEST';
export const CART_GET_ALL_ITEMS_SUCCESS = 'CART_GET_ALL_ITEMS_SUCCESS';
export const CART_GET_ALL_ITEMS_FAIL = 'CART_GET_ALL_ITEMS_FAIL';



export const CARD_REMOVE_ITEM = 'CARD_REMOVE_ITEM';




export const CARD_REMOVE_ALL_ITEM = 'CARD_REMOVE_ALL_ITEM';
export const CARD_REMOVE_ALL_ITEM_REQUEST = 'CARD_REMOVE_ALL_ITEM_REQUEST';
export const CARD_REMOVE_ALL_ITEM_FAIL = 'CARD_REMOVE_ALL_ITEM_FAIL';



export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';

