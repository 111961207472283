import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/action';
import { Link, Navigate } from "react-router-dom";
import './Login.css';
const Login = () => {
  const { client, loading } = useSelector((state) => state.client);
  const [email, setEmail] = useState("");

  const [mp, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!loading) {
      dispatch(login({ email, mp }));
      setShowError(false);
    } else {
      setShowError(true);
    }
  }

  const handleTryAgain = () => {
    setShowError(false);
  }

  if (localStorage.getItem("token") && client) {
    return <Navigate to="/" />;
  }

  return (
    <div >
      {loading ? (
        <h1>Loading.......</h1>
      ) : (
        showError ? (
          <div className="error-page">
            <div className="try-again" onClick={handleTryAgain}>Error: Try again?</div>
          </div>
        ) : (
          <div className="Login-popup">
         
            <div className="login-popup-container">
              <form onSubmit={handleSubmit}>
              <div className="login-popup-title ">
              <h2>Connection</h2>
              </div>
              <div className="login-popup-inputs">
                {/* <h3>Email:</h3> */}
                <input
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                {/* <h3>Password:</h3> */}
                <input
                  type="password"
                  placeholder="Password"
                  value={mp}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                {/* <br /> */}
                <button
                  type="submit"
                  value="Connection"
                   id=" login-popup-container "
                  disabled={loading}
                > Connection</button>
                {/* <br /> */}
             
                <p > Créer un nouveau compte?
             <span>  <Link to="/Signup" className=""> <u>Cliquez ici</u></Link></span>  </p>
                {/* <br /> */}
                <Link to="#" className="no-access">Vous ne parvenez pas à accéder à votre compte ?</Link>
                </div>
              </form>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default Login;
