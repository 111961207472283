import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import "./Checkout.css";
import { getCarts, Switch, AddBonCommandeEntéte, AddBonCommande, PrixTotal, removeAllCart } from '../redux/action';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import Alert from 'react-bootstrap/Alert';
import Footer from '../components/Footer';
const Checkout = () => {
    const dispatch = useDispatch();
    const { cartItems } = useSelector((state) => state.cart);
    const { client } = useSelector((state) => state.client);
    console.log(client);

    useEffect(() => {
        dispatch(getCarts(client.codcli));
    }, [dispatch]);

    const email = client?.email || '';
    const raisoc = client?.raisoc || '';
    const telephone = client?.tel1 || '';
    const adresse = client?.adr || '';

    const [loading, setLoading] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        let calculatedTotal = 0;
        cartItems.forEach(el => {
            calculatedTotal += parseFloat(el.SousTT);
        });
        setTotal(calculatedTotal.toFixed(2));
    }, [cartItems]);
    const [isButtonVisible, setButtonVisibility] = useState(true);

    const handleCommanderClick = async () => {
        setLoading(true);
        try {
            const reponse0 = await Switch();
            console.log('Switch response:', reponse0.data);

            const response1 = await AddBonCommandeEntéte({
                codcli: client.codcli,
                raisoc: client.raisoc,
                datfac: new Date().toISOString(),
            });
            console.log('AddBonCommandeEntéte response:', response1.data);

            await Promise.all(cartItems.map(async (item, index) => {
                const response2 = await AddBonCommande({
                    numfac: response1.data,
                    numlig: index + 1,
                    codeArt: item.codart,
                    quantity: item.QTE,
                    desart: item.desart,
                    datfac: new Date().toISOString(),
                    priuni: item.PUDTTC,
                });
                console.log(`AddBonCommande ${index + 1} response:`, response2.data);
            }));

            const response3 = await PrixTotal({
                numfac: response1.data,
            });
            console.log('PrixTotal response:', response3.data);

            setLoading(false);
            setOrderSuccess(true);
            // alert("");

        } catch (error) {
            setLoading(false);
            console.error('Error during command:', error);
            alert("Une erreur s'est produite lors de la commande. Veuillez réessayer plus tard.");
        }
        setButtonVisibility(false);

        await dispatch(removeAllCart(client.codcli));
    };

    return (
     
        <div>   
            <div className=" border-bottom p-4 text-white mb-3">
                <h1 className="display-6">Checkout</h1>
            </div>
            <form  className="place-order">
            <div className="container mb-5" style={{height:"520px"}}>
                <div className="row" style={{padding:'16px 20px 17px 10px'}}>
                    <div className="col-md-8">
                        <div className="mb-3">
                            {/* <div className="card-header">
                                <i className="bi bi-envelope"></i> Contact Info
                            </div> */}
                            <div className="card-body">
                                <div className="row g-2">
                                    <div className="col-md-8">
                                    <label htmlFor="email" className="form-label" style={{marginLeft:'-320px'}}>Email Address</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email Address"
                                            value={email}
                                            readOnly
                                            aria-label="Email Address"
                                        />
                                    </div>
                                    <div className="col-md-8"></div>
                                    <div className="col-md-8">
                                    <label htmlFor="telephone" className="form-label"style={{marginLeft:'-340px'}}>Mobile no</label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            placeholder="Mobile no"
                                            value={telephone}
                                            readOnly
                                            aria-label="Mobile no"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            {/* <div className="card-header">
                                <i className="bi bi-truck"></i> Shipping Information
                            </div> */}
                            <div className="card-body">
                                <div className="row g-2">
                                    <div className="col-md-8">
                                    <label htmlFor="raisoc" className="form-label" style={{marginLeft:'-365px'}}>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name"
                                            value={raisoc}
                                            readOnly
                                            required
                                        />
                                    </div>
                                    <div className="col-md-8"></div>
                                    <div className="col-md-8">
                                    <label htmlFor="adresse" className="form-label" style={{marginLeft:'-350px'}}>Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address"
                                            value={adresse}
                                            readOnly
                                            required
                                        />
                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>

               
                    
                        <div className=" col-md-4">
                            <div className="card-header border-bottom ">
                                <i className="bi bi-cart3"> 
                                    <div className="cart-total">
                                            <h2>Totals</h2>
                                            </div></i> 
                                    <li className="list-group-item d-flex justify-content-between">
                                    <span>Produit </span>
                                    <strong><span className="badge bg-secondary float-end">{cartItems.length}</span></strong>
                                </li>
                                {/* <span className="badge bg-secondary float-end">{cartItems.length}</span> */}
                            </div>
                            <ul className="list-group list-group-flush">
                                {cartItems.map((item, index) => (
                                    <li className="list-group-item d-flex justify-content-between lh-sm" key={index}>
                                        <div>
                                            <h6 className="my-0">{item.desart} </h6>
                                            <small className="text-muted">Quantity: {item.QTE}</small>
                                        </div>
                                        <span className="text-muted">{item.SousTT}DT</span>
                                    </li>
                                ))}
                                <li className="list-group-item d-flex justify-content-between">
                                    <span>Total </span>
                                    <strong>{total}DT</strong>
                                </li>
                            </ul>
                    <div className="">
                        <div className="card-body ">
                                {isButtonVisible && (
                                    <Button
                                        variant="warning"
                                        style={{ fontSize: "20px"}}
                                        className='input'
                                        onClick={handleCommanderClick}
                                        disabled={loading}
                                    >
                                        {loading ? 'En cours...' : 'Commander'}

                                    </Button>

                                )}
                                {orderSuccess && (
                                 
                                        <Alert variant="success">
                                            Votre commande a été enregistrée avec succès!
                                        </Alert>
                                  
                                )}
                            </div>
                    </div>
                        </div>
                     
                    
                       
                    
                </div>
            </div>
            </form>
            <Footer />
        </div>
    );
};

export default Checkout;
