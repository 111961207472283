
import React, { useEffect, useState } from "react";
import { IconButton, Box } from "@mui/material";
import { Button, Table, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import { removeFromCart, getCarts } from "../redux/action";
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
const Cart = () => {
    const { cartItems } = useSelector((state) => state.cart);
    const { client } = useSelector((state) => state.client);
    const [total, setTotal] = useState("0.00");
    const dispatch = useDispatch();

    useEffect(() => {
        if (client && client.codcli) {
            dispatch(getCarts(client.codcli));
        }
    }, [dispatch, client]);

    useEffect(() => {
        let calculatedTotal = 0;
        cartItems.forEach(el => {
            calculatedTotal += parseFloat(el.SousTT);
        });
        setTotal(calculatedTotal.toFixed(2));
    }, [cartItems]);

    const handleRemoveFromCart = async (codart, codcli) => {
        await dispatch(removeFromCart(codart, codcli));
        dispatch(getCarts(client.codcli)); // Re-fetch the cart items to update the state
    };

    const getPhotoSrc = (photoData) => {
        if (photoData && photoData.data) {
            // Convert binary data to base64
            const base64String = btoa(
                String.fromCharCode(...new Uint8Array(photoData.data))
            );
            return `data:image/jpeg;base64,${base64String}`;
        }
        return ''; // Return an empty string or a placeholder if no valid photo data
    };

    return (
        <div >
            <div className="cart p-4 text-white mb-3">
                <h1 className="display-6 border-bottom">Shopping Cart</h1>
            </div>
            <Container  className="mb-3" style={{height:"600px", marginTop: "30px" }}>
                <Row>
                    <Col md={12}>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Photo</th>
                                        <th>Article Name</th>
                                        <th>Quantity</th>
                                        <th>SousTT</th>
                                        <th>Details</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartItems && cartItems.map((el, index) => (
                                        el && (
                                            <tr key={index}>
                                                <td>
                                                    {/* Check if photo data exists */}
                                                    {el.Photo ? (
                                                        <img 
                                                            className='food-item-image' 
                                                            // src={getPhotoSrc(el.Photo)} 
                                                            src={`data:image/*;base64,${el.Photo}`} 
                                                            alt={el.desart || 'Product Image'} 
                                                            style={{ height: '80px',width:'80px' }} 
                                                        />
                                                    ) : (
                                                        <span>No image available</span>
                                                    )}
                                                </td>
                                                <td>{el.desart || "No details"}</td>
                                                <td>{el.QTE}</td>
                                                <td>{el.SousTT}</td>
                                                <td>
                                                    {el ? (
                                                        <ul>
                                                            <li>Couleur:
                                                                <Box component="span" sx={{
                                                                    display: 'inline-block',
                                                                    width: 16,
                                                                    height: 16,
                                                                    borderRadius: '50%',
                                                                    backgroundColor: el.chdec,
                                                                    ml: 1
                                                                }} />
                                                            </li>
                                                            <li>Price: {el.PUDTTC} DT</li>
                                                        </ul>
                                                    ) : "No details available"}
                                                </td>
                                                <td>
                                                    <IconButton onClick={() => handleRemoveFromCart(el.codart, el.codcli)}>
                                                        <DeleteIcon style={{ color: '#e74c3c', fontSize: "30px" }} />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="6">
                                            <Link to="/" className="btn btn-secondary float-start">
                                                <i className="bi bi-chevron-left"></i> Continue shopping
                                            </Link>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    </Col>
                    <Row>
                        <Col md={8}>
                        </Col>
                    <Col md={4}>
                        <dl className="row border-bottom">
                            <dt className="col-6">Total price:</dt>
                            <dd className="col-6 text-end">{`${total} DT`}</dd>
                        </dl>
                        <dl className="row border-bottom">
                            <dd col="2">
                                {client ? (
                                    <Link to="/Checkout">
                                        <Button variant="warning">
                                           Commander
                                        </Button>
                                    </Link>
                                ) : (
                                    <Link to="/login">
                                        <Button variant="danger">
                                        Commander
                                        </Button>
                                    </Link>
                                )}
                            </dd>
                        </dl>
                    </Col>
                    </Row>
                
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default Cart;
