// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { getAllFamProducts, getArticlesByFamily, getBySousFamArt, getArticlesByFamArtAndSousFam } from '../redux/action';
// import { Container, Nav } from 'react-bootstrap';
// import "./ListFamProd.css"; // Ensure you import your custom CSS file

// const ListFamProd = () => {
//     const dispatch = useDispatch();
//     const { families, sousfamilies } = useSelector(state => state.article);
//     const [selectedFamily, setSelectedFamily] = useState(null);

//     useEffect(() => {
//         dispatch(getAllFamProducts());
//     }, [dispatch]);

//     const handleFamilyClick = (familyCode) => {
//         dispatch(getArticlesByFamily(familyCode));
//         dispatch(getBySousFamArt(familyCode));
//         setSelectedFamily(familyCode);
//     };

//     const handleSousFamilyClick = (familyCode, sousFamilyCode) => {
//         dispatch(getArticlesByFamArtAndSousFam(familyCode, sousFamilyCode));
//     };

//     return (
//         <Container>
//                 <Nav  justify variant="tabs" className="p-3 mb-2 bg-secondary text-white hover-bg-yellow nav" style={{marginTop:"20px"}} >
                    
//                     {families.map((family, index) => (
//                         <Nav.Item key={index}>
//                             <Nav.Link onClick={() => handleFamilyClick(family.code)} className="text-white font-weight-bold hover-bg-yellow">
//                                 {family.lib}
//                             </Nav.Link>
//                         </Nav.Item>
//                     ))}
//                 </Nav>
//                 {selectedFamily && (
//                     <div>
//                         <Nav justify variant="tabs" className="p-3 mb-2 bg-secondary text-white hover-bg-yellow">
//                             {sousfamilies.map((sousfamily, index) => (
//                                 <Nav.Item key={index}>
//                                     <Nav.Link onClick={() => handleSousFamilyClick(sousfamily.chdec, sousfamily.lib)} className="text-white font-weight-bold hover-bg-yellow">
//                                         {sousfamily.lib}
//                                     </Nav.Link>
//                                 </Nav.Item>
//                             ))}
//                         </Nav>
//                     </div>
//                 )}
           
//         </Container>
//     );
// };

// export default ListFamProd;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllFamProducts, getArticlesByFamily, getBySousFamArt, getArticlesByFamArtAndSousFam } from '../../redux/action';
import { Container, Nav } from 'react-bootstrap';
import "./ListFamProd.css";

const ListFamProd = () => {
    const dispatch = useDispatch();
    const { families, sousfamilies } = useSelector(state => state.article);
    const [selectedFamily, setSelectedFamily] = useState(null);
    const [visibleFamilies, setVisibleFamilies] = useState([]);
    const [visibleSousFamilies, setVisibleSousFamilies] = useState([]);
    const [familyStartIndex, setFamilyStartIndex] = useState(0);
    const [sousFamilyStartIndex, setSousFamilyStartIndex] = useState(0);

    useEffect(() => {
        dispatch(getAllFamProducts());
    }, [dispatch]);

    useEffect(() => {
        setVisibleFamilies(families.slice(0, 5));
    }, [families]);

    useEffect(() => {
        if (selectedFamily) {
            setVisibleSousFamilies(sousfamilies.slice(0, 5));
        }
    }, [sousfamilies, selectedFamily]);

    const handleFamilyClick = (familyCode) => {
        dispatch(getArticlesByFamily(familyCode));
        dispatch(getBySousFamArt(familyCode));
        setSelectedFamily(familyCode);
        setSousFamilyStartIndex(0);
    };

    const handleSousFamilyClick1 = (familyCode, sousFamilyCode) => {
        dispatch(getArticlesByFamArtAndSousFam(familyCode, sousFamilyCode));
    };
    const handleSousFamilyClick = (familyCode, sousFamilyCode) => {
                dispatch(getArticlesByFamArtAndSousFam(familyCode, sousFamilyCode));
            };
    const handleNextFamily = () => {
        const newStartIndex = familyStartIndex + 5;
        if (newStartIndex < families.length) {
            setFamilyStartIndex(newStartIndex);
            setVisibleFamilies(families.slice(newStartIndex, newStartIndex + 5));
        }
    };

    const handlePrevFamily = () => {
        const newStartIndex = familyStartIndex - 5;
        if (newStartIndex >= 0) {
            setFamilyStartIndex(newStartIndex);
            setVisibleFamilies(families.slice(newStartIndex, newStartIndex + 5));
        }
    };

    const handleNextSousFamily = () => {
        const newStartIndex = sousFamilyStartIndex + 5;
        if (newStartIndex < sousfamilies.length) {
            setSousFamilyStartIndex(newStartIndex);
            setVisibleSousFamilies(sousfamilies.slice(newStartIndex, newStartIndex + 5));
        }
    };

    const handlePrevSousFamily = () => {
        const newStartIndex = sousFamilyStartIndex - 5;
        if (newStartIndex >= 0) {
            setSousFamilyStartIndex(newStartIndex);
            setVisibleSousFamilies(sousfamilies.slice(newStartIndex, newStartIndex + 5));
        }
    };

    return (
        <Container>
            <Nav justify variant="tabs" className="explore-menu-list" style={{ marginTop: "20px", overflowX: "auto" }}>
                <Nav.Item>
                    <button onClick={handlePrevFamily} disabled={familyStartIndex === 0} className="btn">⟨ </button>
                </Nav.Item>
                {visibleFamilies.map((family, index) => (
                    <Nav.Item key={index}>
                        <Nav.Link onClick={() => handleFamilyClick(family.code)} className="">
                            {family.lib}
                        </Nav.Link>
                    </Nav.Item>
                ))}
                <Nav.Item>
                    <button onClick={handleNextFamily} disabled={familyStartIndex + 5 >= families.length} className="btn">⟩</button>
                </Nav.Item>
            </Nav>
            {selectedFamily && (
                <div>
                    <Nav justify variant="tabs" className="p-3 mb-2 explore-menu-list" style={{ overflowX: "auto" }}>
                        <Nav.Item>
                            <button onClick={handlePrevSousFamily} disabled={sousFamilyStartIndex === 0} className="btn">⟨ </button>
                        </Nav.Item>
                        {visibleSousFamilies.map((sousfamily, index) => (
                            <Nav.Item key={index}>
                                 <Nav.Link onClick={() => handleSousFamilyClick(sousfamily.chdec, sousfamily.lib)} className="">
                                         {sousfamily.lib}
                                   </Nav.Link>
                                {/* <Nav.Link onClick={() => handleSousFamilyClick(selectedFamily, sousfamily.chdec)} className="text-white font-weight-bold" className="text-white font-weight-bold hover-bg-yellow">>
                                    {sousfamily.lib}
                                </Nav.Link> */}
                            </Nav.Item>
                        ))}
                        <Nav.Item>
                            <button onClick={handleNextSousFamily} disabled={sousFamilyStartIndex + 5 >= sousfamilies.length} className="btn">⟩</button>
                        </Nav.Item>
                    </Nav>
                </div>
            )}
        </Container>
    );
};

export default ListFamProd;

