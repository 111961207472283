// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { signUp } from '../redux/action'; // Assurez-vous d'importer la bonne action
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import { Navigate } from 'react-router-dom';

// const Signup = () => {
//     const { loading } = useSelector((state) => state.client);
//     const dispatch = useDispatch();
   
//     const [raisoc, setRaisoc] = useState("");
//     const [adr, setAdr] = useState("");
//     const [tel1, setTel1] = useState("");
//     const [email, setEmail] = useState("");
//     const [mp, setMp] = useState("");

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         const newClient = {raisoc, adr, tel1, email,mp};
//         dispatch(signUp(newClient));
//     };

//     return (
//         <div id='signUp'>
//             {loading ? (
//                 <h1>Loading ...</h1>
//             ) : localStorage.getItem("token") ? (
//                 <Navigate to="/" />
//             ) : (
//                 <div>
//                     <Form onSubmit={handleSubmit} className='signUp_Form'>
//                         <h2 className='titleSignUp'>Sign Up</h2>
//                         <Form.Group className="mb-3">
//                             <Form.Label>Raisoc</Form.Label>
//                             <Form.Control 
//                                 type="text" 
//                                 placeholder='Raisoc' 
//                                 value={raisoc} 
//                                 onChange={e => setRaisoc(e.target.value)} 
//                             />
//                         </Form.Group>

//                         <Form.Group className="mb-3">
//                             <Form.Label>Adresse</Form.Label>
//                             <Form.Control 
//                                 type="text" 
//                                 placeholder='Adresse' 
//                                 value={adr} 
//                                 onChange={e => setAdr(e.target.value)} 
//                             />
//                         </Form.Group>

//                         <Form.Group className="mb-3" controlId="formBasicTel">
//                             <Form.Label>Téléphone</Form.Label>
//                             <Form.Control 
//                                 type="text" 
//                                 placeholder='Téléphone' 
//                                 value={tel1} 
//                                 onChange={e => setTel1(e.target.value)} 
//                             />
//                         </Form.Group>

//                         <Form.Group className="mb-3" controlId="formBasicEmail">
//                             <Form.Label>Email</Form.Label>
//                             <Form.Control 
//                                 type="email" 
//                                 placeholder='Entrer votre email' 
//                                 value={email} 
//                                 onChange={e => setEmail(e.target.value)} 
//                             />
//                         </Form.Group>

//                         <Form.Group className="mb-3" controlId="formBasicPassword">
//                             <Form.Label>Mot de passe</Form.Label>
//                             <Form.Control 
//                                 type="password" 
//                                 placeholder='Mot de passe' 
//                                 value={mp} 
//                                 onChange={e => setMp(e.target.value)} 
//                             />
//                         </Form.Group>

//                         <hr />
//                         <Button style={{ marginBottom: "20px" }} variant="primary" type="submit">
//                             S'inscrire
//                         </Button>
//                     </Form>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Signup;


import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signUp } from '../redux/action';
import {Link, Navigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './Login.css';
const Signup = () => {
    const { loading } = useSelector((state) => state.client);
    const dispatch = useDispatch();
   
    const [raisoc, setRaisoc] = useState("");
    const [adr, setAdr] = useState("");
    const [tel1, setTel1] = useState("");
    const [email, setEmail] = useState("");
    const [mp, setMp] = useState("");
    const [passager, setpassager] = useState(1);
    const handleSubmit = (e) => {
        e.preventDefault();
        const newClient = {raisoc, adr, tel1, email, mp,passager};
        dispatch(signUp(newClient));
    };

    return (
        <div>
            {loading ? (
                <h1>Loading ...</h1>
            ) : localStorage.getItem("token") ? (
                <Navigate to="/" />
            ) : (
                <div className="Login-popup">
         
                <div className="login-popup-container">
                        <Form onSubmit={handleSubmit}>
                       <div className="login-popup-title">
                        <h2>Inscription</h2>
                    </div>
                    <div className="login-popup-inputs">
                
                             
                                <input
                                    type="text" 
                                    placeholder='Raison social' 
                                    value={raisoc} 
                                    onChange={e => setRaisoc(e.target.value)} 
                                    required
                                />
                          
                                
                                <input
                                    type="text" 
                                    placeholder='Adresse' 
                                    value={adr} 
                                    onChange={e => setAdr(e.target.value)} 
                                    required
                                />
                           
                          
                                <input
                                    type="text" 
                                    placeholder='Numéro Telephone' 
                                    value={tel1} 
                                    onChange={e => setTel1(e.target.value)} 
                                    required
                                />
                          
                                <input
                                    type="email" 
                                    placeholder='Entrer votre email' 
                                    value={email} 
                                    onChange={e => setEmail(e.target.value)} 
                                    required
                                />
                        
                                <input 
                                    type="password" 
                                    placeholder='Mot de passe' 
                                    value={mp} 
                                    onChange={e => setMp(e.target.value)} 
                                    required
                                />
                          
                 <button
                  type="submit"
                  value="S'inscrire"
                  id="login-popup-container "
                  disabled={loading}
                  > S'inscrire</button>
               </div>
                      
          
    <p style={{marginTop:'10px'}}> Vous avez déjà un compte?
   <span> <Link to="/login" className=""> <u>Connectez-vous ici</u></Link></span></p>
                <br />
                <Link to="#" className="no-access">Vous ne parvenez pas à accéder à votre compte ?</Link>

                            {/* <Button style={{ marginBottom: "20px" }} variant="success"  type="submit">
                                S'inscrire
                            </Button> */}
                          
                        </Form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Signup;
