import axios from "axios";
import bcrypt from 'bcryptjs';
import {

    SIGN_UP, SIGN_UP_SUCCESS, SIGN_UP_FAIL,
    LOGIN, LOGIN_SUCCESS, LOGIN_FAIL,
    LOGOUT, BLOCK_CLIENT,
    ADD_CLIENT, ADD_CLIENT_SUCCESS, ADD_CLIENT_FAIL,
    GET_ALL_CLIENTS, GET_CLIENT_PROFILE, GET_CLIENT_PROFILE_SUCCESS, GET_CLIENT_PROFILE_FAIL,
    DELETE_CLIENT,
    ADD_ARTICLE, ADD_ARTICLE_SUCCESS, ADD_ARTICLE_FAIL,
    GET_ALL_ARTICLES, GET_ARTICLE_DETAIL, GET_ARTICLE_DETAIL_SUCCESS, GET_ARTICLE_DETAIL_FAIL,
    DELETE_ARTICLE, UPDATE_ARTICLE,
    GET_ARTICLE_DES_FAIL,
    GET_ARTICLE_DES_SUCCESS,
    GET_ARTICLE_DES,
    GET_ACC_PRODUCTS_SUCCESS,
    GET_ACC_PRODUCTS_FAIL,
    GET_SF01_PRODUCTS_SUCCESS,
    GET_SF01_PRODUCTS_FAIL,
    GET_ACC_PRODUCTS,
    GET_SF01_PRODUCTS,
    GET_ARTICLES_BY_FAMILY_REQUEST,
    GET_ARTICLES_BY_FAMILY_SUCCESS,
    GET_ARTICLES_BY_FAMILY_FAILURE,
    GET_ALL_FAM_PRODUCTS,
    GET_ALL_FAM_PRODUCTS_ERROR,
    GET_ALL_ARTICLES_FAIL,
    GET_ALL_ARTICLES_SUCCESS,
    GET_BY_SOUS_FAM_ART_REQUEST,
    GET_BY_SOUS_FAM_ART_SUCCESS,
    GET_BY_SOUS_FAM_ART_FAILURE,
    GET_ARTICLES_BY_FAMART_AND_SOUSFAM_REQUEST,
    GET_ARTICLES_BY_FAMART_AND_SOUSFAM_SUCCESS,
    GET_ARTICLES_BY_FAMART_AND_SOUSFAM_FAILURE,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_CLIENT_FAILURE,
    CARD_ADD_ITEM,
    CARD_ADD_ITEM_SUCCESS,
    CARD_ADD_ITEM_FAIL,
    CARD_REMOVE_ITEM,

   
    INCREMENT,
    DECREMENT, 
    CART_GET_ITEMS_REQUEST,
    CART_GET_ITEMS_SUCCESS,
    CART_GET_ITEMS_FAIL,
    CART_UPDATE_ITEM,
    CART_UPDATE_ITEM_SUCCESS,
    CART_UPDATE_ITEM_FAIL,
    CART_GET_ALL_ITEMS_REQUEST,
    CART_GET_ALL_ITEMS_SUCCESS,
    CART_GET_ALL_ITEMS_FAIL,
    CARD_REMOVE_ALL_ITEM,
  CARD_REMOVE_ALL_ITEM_REQUEST,
  CARD_REMOVE_ALL_ITEM_FAIL,

} from "./actionTypes";


// Actions du client
export const getAllClients = () => async (dispatch) => {
    try {
        const res = await axios.get("/client/getAllClients");
        dispatch({ type: GET_ALL_CLIENTS, payload: res.data });
    } catch (error) {
        console.error("Erreur lors de la récupération de tous les clients", error);
    }
};

const SALT_ROUNDS = 10;

const hashPassword = async (password) => {
    try {
        const hashedPassword = await bcrypt.hash(password, SALT_ROUNDS);
        return hashedPassword;
    } catch (error) {
        console.error('Password Hashing Error:', error);
        throw new Error('Password hashing failed');
    }
};


export const signUp = (client) => async (dispatch) => {
  dispatch({ type: SIGN_UP });

  try {
      // Hash the password
      const hashedPassword = await hashPassword(client.mp);

      // Send the hashed password to the server
      const res = await axios.post(`https://www.24-crm.tn/webservice/api/clients?codcli&raisoc=${client.raisoc}&tel1=${client.tel1}&email=${client.email}&adr=${client.adr}&mp=${hashedPassword}&passage=1&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`, client);

      localStorage.setItem("token", res.data.token);
      dispatch({
          type: SIGN_UP_SUCCESS,
          payload: res.data
      });
  } catch (error) {
      console.error('Sign Up Error:', error);
      dispatch({
          type: SIGN_UP_FAIL,
          payload: error.response?.data || 'Sign up failed. Please try again later.'
      });
  }
};



export const login = (client) => async (dispatch) => {
    dispatch({ type: LOGIN });
    try {
   //  const res = await axios.post("/client/signIn", client);

      
         const res = await axios.post(`https://www.24-crm.tn/webservice/api/clients?coddosEcommml=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`, client);

        localStorage.setItem("token", res.data.token);
        console.log(res.data)
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    } catch (error) {
        console.error('Login Error:', error); // Log the full error for debugging
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response?.data || 'Login failed. Please try again later.'
        });
    }
};







//log out
export const logout = () => {

    localStorage.clear();
     localStorage.removeItem('token');
     localStorage.removeItem('persist:authType');
  document.location.href='/';
  
  
  return {
    type: LOGOUT
  }
  
  }



export const blockClient = (clientId) => async (dispatch) => {
    try {
        await axios.put(`/client/blockClient/${clientId}`);
        dispatch({ type: BLOCK_CLIENT, payload: clientId });
    } catch (error) {
        console.error("Erreur de blocage du client", error);
    }
};



export const getClientProfile = (clientId) => async (dispatch) => {
    dispatch({ type: GET_CLIENT_PROFILE });

    const token = localStorage.getItem("token");
    const config = {
        headers: {
            Authorization: token
        }
    };
    try {
        const res = await axios.get("/client/auth", config);
        dispatch({
            type: GET_CLIENT_PROFILE_SUCCESS,
            payload: res.data
        });
    } catch (error) {
        dispatch({
            type: GET_CLIENT_PROFILE_FAIL,
            payload: error.response ? error.response.data : { message: error.message }
        });
    }
};








export const addClient = (newClient) => async (dispatch) => {
    dispatch({ type: ADD_CLIENT });
    try {
        const res = await axios.post("/client/addClient", newClient);
        dispatch({ type: ADD_CLIENT_SUCCESS, payload: res.data });
    } catch (error) {
        dispatch({ type: ADD_CLIENT_FAIL, payload: error.response.data });
    }
};

export const deleteClient = (clientId) => async (dispatch) => {
    try {
        await axios.delete(`/client/deleteClient/${clientId}`);
        dispatch({ type: DELETE_CLIENT, payload: clientId });
    } catch (error) {
        console.error("Erreur lors de la suppression du client", error);
    }
};

export const updateClient = (codCli,clientData) => async (dispatch) => {
    try {
      const res = await axios.put(`/client/updateClient/${codCli}`,clientData);
      dispatch({
        type: UPDATE_CLIENT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CLIENT_FAILURE,
        payload: error.response ? error.response.data : { message: error.message },
      });
      console.error('Erreur lors de la mise à jour du client', error);
    }
  };

// Actions des articles
export const getAllArticles = () => async dispatch => {
    dispatch({ type: GET_ALL_ARTICLES });
    
    try {
     const res = await axios.get(`https://www.24-crm.tn/webservice/api/ARTICLEs?coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);
       //   const res = await axios.get("/article/getAllArticles");

      dispatch({ type: GET_ALL_ARTICLES_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: GET_ALL_ARTICLES_FAIL , payload: error.message });
    }
 
  };

export const getArticleDetail = (codart) => async dispatch => {
    dispatch({ type: GET_ARTICLE_DETAIL });
    try {
      //   const response = await axios.get(`/article/getArticleDetail/${codart}`);
   const response = await axios.get(`https://www.24-crm.tn/webservice/api/ARTICLEs?codartid=${codart}&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);

      dispatch({ type: GET_ARTICLE_DETAIL_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_ARTICLE_DETAIL_FAIL , payload: error.message });
    }
  };

  
  export const getArticleDes = (codart) => async dispatch => {
    dispatch({ type: GET_ARTICLE_DES });
    try {
    //  const response = await axios.get(`/article/getArticleDetailDes/${codart}`);
     const response = await axios.get(`https://www.24-crm.tn/webservice/api/ARTICLEs?codartid=${codart}&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);

      dispatch({ type: GET_ARTICLE_DES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_ARTICLE_DES_FAIL , payload: error.message });
    }
  };


  export const getAccProducts = () => async (dispatch) => {
    dispatch({ type: GET_ACC_PRODUCTS });
    try {
        const res = await axios.get("/article/getAccProducts");
        dispatch({ type: GET_ACC_PRODUCTS_SUCCESS, payload: res.data });
    } catch (error) {
        console.error("Erreur lors de la récupération des produits Acc", error);
        dispatch({ type: GET_ACC_PRODUCTS_FAIL, payload: error.message });
    }
};

// Fonction d'action pour récupérer tous les produits SF01
export const getSF01Products = () => async (dispatch) => {
    dispatch({ type: GET_SF01_PRODUCTS });
    try {
        const res = await axios.get("/article/getSF01Products");
        dispatch({ type: GET_SF01_PRODUCTS_SUCCESS, payload: res.data });
    } catch (error) {
        console.error("Erreur lors de la récupération des produits SF01", error);
        dispatch({ type: GET_SF01_PRODUCTS_FAIL, payload: error.message });
    }
};




export const getAllFamProducts = () => async (dispatch) => {
    try {
        // const res = await axios.get("/article/getAllFamProducts");
        const res = await axios.get("https://www.24-crm.tn/webservice/api/ARTICLEs?allfamart&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF");
        dispatch({ type: GET_ALL_FAM_PRODUCTS, payload: res.data });
    } catch (error) {
        dispatch({ type: GET_ALL_FAM_PRODUCTS_ERROR, payload: error.message });
        console.error("Erreur lors de la récupération de tous les articles", error);
    }
}





export const getArticlesByFamily = (famart) => async (dispatch) => {
    dispatch({ type: GET_ARTICLES_BY_FAMILY_REQUEST });

    try {
        // const response = await axios.get(`/article/getAllFamProducts/${famart}`);
        const response = await axios.get(`https://www.24-crm.tn/webservice/api/ARTICLEs?famart=${famart}&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);
        dispatch({
            type: GET_ARTICLES_BY_FAMILY_SUCCESS,
            payload: response.data, 
        });
    } catch (error) {
        dispatch({
            type: GET_ARTICLES_BY_FAMILY_FAILURE,
            error: error.message, 
        });
    }
};

export const getBySousFamArt = (famart) => async (dispatch) => {
    dispatch({ type: GET_BY_SOUS_FAM_ART_REQUEST });

    try {
      const response = await axios.get(`https://www.24-crm.tn/webservice/api/ARTICLEs?famartsous=${famart}&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);
        // const response = await axios.get(`/article/getBySousFamArt/${famart}`);
        dispatch({
            type: GET_BY_SOUS_FAM_ART_SUCCESS,
            payload: response.data, 
        });
    } catch (error) {
        dispatch({
            type: GET_BY_SOUS_FAM_ART_FAILURE,
            error: error.message, 
        });
    }
};
export const getArticlesByFamArtAndSousFam = (famart, sousfam) => async (dispatch) => {
    dispatch({ type: GET_ARTICLES_BY_FAMART_AND_SOUSFAM_REQUEST });

    try {
        // const response = await axios.get(`/article/getArticlesByFamArtAndSousFam/${famart}/${sousfam}`);
        const response = await axios.get(`https://www.24-crm.tn/webservice/api/ARTICLEs?famart=${famart}&sousfam=${sousfam}&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);
        dispatch({
            type: GET_ARTICLES_BY_FAMART_AND_SOUSFAM_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ARTICLES_BY_FAMART_AND_SOUSFAM_FAILURE,
            error: error.message,
        });
    }
};



export const addArticle = (newArticle) => async (dispatch) => {
    dispatch({ type: ADD_ARTICLE });
    try {
        const res = await axios.post("/article/addArticle", newArticle);
        dispatch({ type: ADD_ARTICLE_SUCCESS, payload: res.data });
    } catch (error) {
        dispatch({ type: ADD_ARTICLE_FAIL, payload: error.response.data });
    }
};

export const deleteArticle = (articleId) => async (dispatch) => {
    try {
        await axios.delete(`/article/deleteArticle/${articleId}`);
        dispatch({ type: DELETE_ARTICLE, payload: articleId });
    } catch (error) {
        console.error("Erreur lors de la suppression de l'article", error);
    }
};

export const updateArticle = (article) => async (dispatch) => {
    try {
        const res = await axios.put(`/article/updateArticle/${article.codart}`, article);
        dispatch({ type: UPDATE_ARTICLE, payload: res.data });
    } catch (error) {
        console.error("Erreur lors de la mise à jour de l'article", error);
    }
};











// action cart 
export const addToCart = (codart,codcli,qte, SousTT, PUDTTC) => async (dispatch) => {
  dispatch({ type: CARD_ADD_ITEM });
  try {
    // const photoData = Photo.replace(/^data:image\/\w+;base64,/, "");,Photo:photoData ,Photo
    const { data } = await axios.get(`https://www.24-crm.tn/webservice/api/panier?codart=${codart}&codcli=${codcli}&qte=${qte}&SousTT=${SousTT}&PUDTTC=${PUDTTC}&coddosEcommPn=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);

  //  const { data } = await axios.post("/cart/addToCart", { codart,codcli, qte, SousTT, PUDTTC});
    console.log("Data from addToCart API:", data); // Vérifiez les données retournées par l'API
    dispatch({
      type: CARD_ADD_ITEM_SUCCESS,
      payload: {
        codart: data.codart,
        QTE: data.QTE,
        SousTT: data.SousTT,
        codcli:data.codcli,
        // Photo:data.photoBuffer,
      },
    });
  } catch (error) {
    dispatch({
      type: CARD_ADD_ITEM_FAIL,
      payload: error.response.data, 
    });
  }
};

  export const getCartItems = (codart,codcli) => async (dispatch) => {
    dispatch({ type: CART_GET_ITEMS_REQUEST });
  
    try {
       // const { data } = await axios.get(`/cart/getCartItems/${codart}`,{codcli});
     const { data } = await axios.get(`https://www.24-crm.tn/webservice/api/ARTICLEs?codclient=${codcli}&codartid=${codart}&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);

      dispatch({
        type: CART_GET_ITEMS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CART_GET_ITEMS_FAIL,
        payload: error.message,
      });
    }
  };
 
  export const getCarts = (codcli) => async (dispatch) => {
    dispatch({ type: CART_GET_ALL_ITEMS_REQUEST });
  
    try {
     //const { data } = await axios.get(`/cart/getCarts/${codcli }`);
        const { data } = await axios.get(`https://www.24-crm.tn/webservice/api/ARTICLEs?codcli=${codcli}&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);

      dispatch({
        type: CART_GET_ALL_ITEMS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CART_GET_ALL_ITEMS_FAIL,
        payload: error.message,
      });
    }
  };
  

  export const removeFromCart = (codart, codcli) => async (dispatch) => {
    try {
    
      // const { data } = await axios.delete(`/cart/removeFromCart/${codart}`, {
    
      const { data } = await axios.delete(`https://www.24-crm.tn/webservice/api/panier?codclit=${codcli}&codart=${codart}&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`, {
        data: { codcli }
      });
      console.error("l'article a été supprimer", data);

      
      dispatch({
        type: CARD_REMOVE_ITEM,
        payload: data
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'article du panier', error);
     
    }
  };

  export const removeAllCart = (codcli) => async (dispatch) => {
    try {
      dispatch({ type: CARD_REMOVE_ALL_ITEM_REQUEST });
      const { data } = await axios.delete(`https://www.24-crm.tn/webservice/api/panier?codclit=${codcli}&coddosEcomm=comaf&serveur=2:9/38/85/226]qpm-2544&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);
     // const { data } = await axios.delete(`/cart/removeAllCart/${codcli}`);
      console.log("Tous les articles supprimés du panier", data);
  
      dispatch({
        type: CARD_REMOVE_ALL_ITEM,
        payload: data
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de tous les articles du panier', error);
      dispatch({
        type: CARD_REMOVE_ALL_ITEM_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  }









// incrementer
export const incrementQty = (cartItem) => (dispatch) => {
  dispatch(
    {
      type: INCREMENT,
      payload: cartItem
    }
  )
}; 

// decrementer 
export const decrementQty = (cartItem) => (dispatch) => {
  dispatch(
    {
      type: DECREMENT,
      payload: cartItem
    }
  )
};

export const updateCartItem = (codart,codcli, qte, SousTT, PUDTTC) => async (dispatch) => {
    dispatch({ type: CART_UPDATE_ITEM });
    try {
      const data = await axios.put(`/cart/updateCartItem/${codart}`, { qte,codcli, SousTT, PUDTTC });
    
      dispatch({
        type: CART_UPDATE_ITEM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CART_UPDATE_ITEM_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
};


export const AddBonCommandeEntéte = async (order) => {
  return await axios.post(`https://www.24-crm.tn/webservice/api/BCDVCLIs?numfac&typfac=BC&taurem=0&codcli=${order.codcli}&raisoc=${order.raisoc}&catfisc=0&datfac=${order.datfac}&timbre=false&tauxNASS=0&vendeur=e_commerce&valtimbre=0&codrep=&pj=&smNASS=&0&CodDep=&coddos=comaf&serveur=/]qpm&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);
}
export const Switch  = async () => {
  return await axios.put(`https://www.24-crm.tn/webservice/api/Switch?code=BC2&valeur&coddos=comaf&serveur=/]qpm&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);
}


export const AddBonCommande= async (order) => {
console.log(order)
  return await axios.post(`https://www.24-crm.tn/webservice/api/LigBCDV/%7BID%7D?numfac=${order.numfac}&typfac=BC&numlig=${order.numlig}&codart=${order.codeArt}&quantite=${order.quantity}&fodart=0&desart=${order.desart}&datfac=${order.datfac}&priuni=${order.priuni}&remise=0&unite=PC&codtva=0&tautva=0&qtecom=2&CB=&formqt=0&pmp=0&coddos=comaf&serveur=/]qpm&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`);
}
// export const updateAdmin=async()=>
// {
//   return await axios.post(`https://www.24-crm.tn/webservice/api/Auth?code=CRM&nom=crm&mp=crm&grp=100&NBS=&CodeDep=&mps=&remise=&coddos=comaf&serveur=/]qpm&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSDOSSIER`)
// }

       
export const  PrixTotal = async (order) => {
  const response = await axios.post( `https://www.24-crm.tn/webservice/api/LigBCDV?FAC=${order.numfac}&typfacc=BC&coddos=comaf&serveur=/]qpm&user=tb&pwd=qpmztpgubinfenf{hboj&database=PSCOMAF`,);

  if (response.data) {
    return response.data
  }

}

