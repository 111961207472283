// reducerArticle.js
import {
    GET_ALL_ARTICLES,
    GET_ARTICLE_DETAIL,
    GET_ARTICLE_DETAIL_SUCCESS,
    GET_ARTICLE_DETAIL_FAIL,
    ADD_ARTICLE,
    ADD_ARTICLE_SUCCESS,
   
    DELETE_ARTICLE,
    UPDATE_ARTICLE,
    GET_ARTICLE_DES,
    GET_ARTICLE_DES_SUCCESS,
    GET_ARTICLE_DES_FAIL,

    GET_ACC_PRODUCTS,
    GET_SF01_PRODUCTS,


    GET_ACC_PRODUCTS_SUCCESS,
    GET_SF01_PRODUCTS_SUCCESS,

    GET_ACC_PRODUCTS_FAIL,
    GET_SF01_PRODUCTS_FAIL,
    GET_ARTICLES_BY_FAMILY_REQUEST,
    GET_ARTICLES_BY_FAMILY_SUCCESS,
    GET_ARTICLES_BY_FAMILY_FAILURE,
    GET_ALL_FAM_PRODUCTS,
    GET_ALL_FAM_PRODUCTS_ERROR,
    GET_ALL_ARTICLES_SUCCESS,
    GET_ALL_ARTICLES_FAIL,
    GET_BY_SOUS_FAM_ART_REQUEST,
    GET_BY_SOUS_FAM_ART_FAILURE,
    GET_BY_SOUS_FAM_ART_SUCCESS,
    GET_ARTICLES_BY_FAMART_AND_SOUSFAM_REQUEST,
    GET_ARTICLES_BY_FAMART_AND_SOUSFAM_SUCCESS,
    GET_ARTICLES_BY_FAMART_AND_SOUSFAM_FAILURE,

} from "./actionTypes";

const initialState = {
    articles: null,
    article: null,
    description: null,
    families: [],
    sousfamilies:[],
    error: null,
    loading: false,
};

export const articleReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_ARTICLE:
        case GET_ALL_ARTICLES:
        case GET_ARTICLE_DETAIL:
        case GET_ACC_PRODUCTS:
        case GET_SF01_PRODUCTS:
        case GET_ARTICLE_DES:
        case GET_ARTICLES_BY_FAMILY_REQUEST:
        case GET_BY_SOUS_FAM_ART_REQUEST:
        case GET_ARTICLES_BY_FAMART_AND_SOUSFAM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_ARTICLE_SUCCESS:
            return {
                ...state,
                articles: state.articles ? [...state.articles, payload] : [payload],
                loading: false,
                error: null,
            };

        case GET_ARTICLE_DETAIL_SUCCESS:
        
            return {
                ...state,
                article: payload,
                loading: false,
                error: null,
            };

        case GET_ARTICLE_DES_SUCCESS:
            return {
                ...state,
                description :payload,
                loading: false,
                error: null,     }


        case GET_ALL_ARTICLES_FAIL:
        case GET_ARTICLE_DETAIL_FAIL:
        case GET_ARTICLE_DES_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case GET_ALL_ARTICLES_SUCCESS:
            return {
                ...state,
                articles: payload,
                loading: false,
                error: null,
            };


            case GET_ACC_PRODUCTS_SUCCESS:
                case GET_SF01_PRODUCTS_SUCCESS:
                    return {
                        ...state,
                        articles: payload,
                        loading: false,
                        error: null
                    };
        
                case GET_ACC_PRODUCTS_FAIL:
                case GET_SF01_PRODUCTS_FAIL:
                    return {
                        ...state,
                        loading: false,
                        error: payload
                    };









        case DELETE_ARTICLE:
            return {
                ...state,
                articles: state.articles.filter(article => article._id !== payload),
            };
        case UPDATE_ARTICLE:
            return {
                ...state,
                articles: state.articles.map(article => article._id === payload._id ? payload : article),
            };

            case GET_ALL_FAM_PRODUCTS:
           
                return {
                    ...state,
                    families: payload,  
                    error: false,           
                };


            case GET_BY_SOUS_FAM_ART_SUCCESS:
                return {
                    ...state,
                    sousfamilies: payload,  
                    error: false,             
                };




            case GET_ALL_FAM_PRODUCTS_ERROR:
            case GET_BY_SOUS_FAM_ART_FAILURE:
                return {
                    ...state,
                    error: payload,  
                };





            case GET_ARTICLES_BY_FAMILY_SUCCESS:
            case GET_ARTICLES_BY_FAMART_AND_SOUSFAM_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    articles: payload, 
                };




            case GET_ARTICLES_BY_FAMILY_FAILURE:
            case GET_ARTICLES_BY_FAMART_AND_SOUSFAM_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: payload, 
                };








        default:
            return state;
    }
};
