import { combineReducers } from "redux"
import {clientReducer} from "./reducerClient"
import {articleReducer} from "./reducerArticle"
import cartReducer from "./reducerCart"

const rootReducers= combineReducers({

    article:articleReducer,
    client:clientReducer ,
    cart:cartReducer,


})
export default rootReducers