import { 
  CARD_ADD_ITEM, 
  CARD_ADD_ITEM_FAIL, 
  CARD_ADD_ITEM_SUCCESS, 
  CARD_REMOVE_ITEM, 

  CART_GET_ITEMS_REQUEST,
  CART_GET_ITEMS_SUCCESS,
  CART_GET_ITEMS_FAIL,
  CART_UPDATE_ITEM,
  CART_UPDATE_ITEM_SUCCESS,
  CART_UPDATE_ITEM_FAIL ,
  CART_GET_ALL_ITEMS_REQUEST,
  CART_GET_ALL_ITEMS_SUCCESS,
  CART_GET_ALL_ITEMS_FAIL,
  CARD_REMOVE_ALL_ITEM,
  CARD_REMOVE_ALL_ITEM_REQUEST,
  CARD_REMOVE_ALL_ITEM_FAIL


} from "./actionTypes";

const init = {
  cartItems: [],
  // cartsItemsdetail:[],
  cartItemsdetail:null,

  loading: false,
  error: null,
};

export const cartReducer = (state = init, { type, payload }) => {
  switch (type) {
    case CARD_ADD_ITEM:
      return {
        ...state,
        loading: true,
      };
    case CARD_ADD_ITEM_SUCCESS:
      const newItem = payload;
      const existingItemIndex = state.cartItems.findIndex(item => item.codart === newItem.codart);

      if (existingItemIndex !== -1) {
        // Item already exists in cart, update it
        return {
          ...state,
          loading: false,
          cartItems: state.cartItems.map((item, index) =>
            index === existingItemIndex ? newItem : item
          ),
        };
      } else {
        // Item is new, add it to the cart
        return {
          ...state,
          loading: false,
          cartItems: [...state.cartItems, newItem],
        };
      }
    case CARD_ADD_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        error:payload,
      };
      case CARD_REMOVE_ITEM:
            return {
                ...state,
                cartItems: state.cartItems.filter(
                    item => item.codcli !== payload.codcli || item.codart !== payload.codart
                )
            };
            case CARD_REMOVE_ALL_ITEM_REQUEST:
              return {
                ...state,
                loading: true,
              };
            case CARD_REMOVE_ALL_ITEM:
              return {
                ...state,
                loading: false,
                cartItems: [], 
              };
            case CARD_REMOVE_ALL_ITEM_FAIL:
              return {
                ...state,
                loading: false,
                error: payload,
              };


    case CART_GET_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CART_GET_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        cartItemsdetail: payload ,
      };
    case CART_GET_ITEMS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
  
    case CART_UPDATE_ITEM:
      return { ...state, loading: true };
    case CART_UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        cartItems: state.cartItems.map(item =>
          item.codart === payload.codart ? payload : item
        ),
      };
    case CART_UPDATE_ITEM_FAIL:
      return { ...state, loading: false, error: payload };


      case CART_GET_ALL_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CART_GET_ALL_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        cartItems: payload,
      };
    case CART_GET_ALL_ITEMS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };







    default:
      return state;
  }
};

export default cartReducer;
